<template>
  <div class="taxes">
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <vs-button v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.taxes.title')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <tax-form v-model="formData" @submit="onTaxFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import TaxForm from "@/modules/Shops/Pages/forms/TaxForm.vue";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Taxes",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    TaxForm,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/taxes", {
      taxesList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      taxById: "getById",
    }),

    tableData() {
      return this.taxesList.map(this.taxesToTableData);
    },
    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    }
  },

  methods: {
    ...mapActions("shops/taxes", { 
      loadData: "load",
      createData: "create",
      updateData: "update",
      removeData: "remove"
    }),

    taxesToTableData(tax) {
      return {
        id: tax.id,
        name: tax.name,
        percert: tax.percent,
        updated_at: tax.updated_at,
        created_at: tax.created_at
      };
    },

    async createTax(data) {
      try {
        const tax = await this.createData(data);
        this.formData = Object.assign({}, this.formData, tax);
        this.notifySuccess(
          i18n.t("shops.save"),
          `${i18n.t('shops.tax')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
        );
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_a_tax"));
      }
    },

    async onTaxFormSubmit(tax) {
      this.$vs.loading();
      try {
        tax.resort_id = this.$route.params.uuid;
        if (tax.id === 0) {
          // New object, add to API & store
          await this.createTax(tax);
        } else {
          // Existing object, update in API & store
          await this.update(tax);
        }
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_tax"), error);
      } finally {
        this.$vs.loading.close();
      }
    },

    async update(tax) {
      this.$vs.loading();
      try {
        await this.updateData(tax);
        // Empty form fields
        this.formData = this.default();
        this.showForm = false;
        this.addButton = true;
        this.updateButton = false;

        this.notifySuccess(
          i18n.t("shops.update"),
          `${i18n.t('shops.tax')} ${tax.name} ${i18n.t('shops.has_been_updated_successfully')}`
        );
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_update_tax"), error);
      } finally {
        this.$vs.loading.close();
      }
    },

    onUpdate(tax) {
      this.addButton = false;
      this.updateButton = true;

      const translationsDefault = this.translationsDefault(['name'])

      this.formData = Object.assign({}, this.formData, {
        ...this.taxById(tax.id),
        translations: { ...translationsDefault, ...this.taxById(tax.id).translations }
      });

      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        percent: "",
        name: "",
        translations: this.translationsDefault(['name']),
      }
    },
  },

  created() {
    this.load();
    this.$store.commit("shops/taxes/RESET_RESOURCE");
  },
};
</script>

<style></style>
