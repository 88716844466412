<template>
  <div class="tax-form">
    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="tax.name"
          v-validate="'required'"
          class="w-32"
          name="name"
          :danger="errorName && errors.has('name')"
          :danger-text="$t('shops.errors.name_is_required')" />
        <vs-input v-else v-model="tax.translations[language].name" class="w-32" name="taxName" />
      </div>
    </div>
    <div class="vx-col w-1/3">
        <label class="vs-input--label w-1/2">{{ $t("shops.percent") }}</label>
        <vs-input
          v-model="tax.percent"
          v-validate="'required|max_value:100'"
          class="w-32"
          name="percent"
          :danger="errorPercent && errors.has('percent')"
        />
    </div>
    <div class="vx-col w-1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "TaxForm",

  props: {
    value: { type: Object, default: null }
  },

  mixins: [ notifications, shopLanguages ],

  data() {
    return {
      errorName: false,
      errorPercent: false,
      tax: {} 
    };
  },

  methods: {
    validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
          carry[lang] = this.tax.translations[lang]
          return carry
        }, {}), ...this.tax}

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submit", payload);
        } else {
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.tax_validation_failed"));
          if (!this.tax.name) this.errorName = true;
          if (!this.tax.percent) this.errorPercent = true;
        }
      })
    }
  },

  created() {
    this.tax = JSON.parse(JSON.stringify(this.value))
  }
};
</script>

<style></style>
