var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-form" }, [
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col" },
          [
            _c("label", { staticClass: "vs-input--label w-1/2" }, [
              _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-32",
                  attrs: {
                    name: "name",
                    danger: _vm.errorName && _vm.errors.has("name"),
                    "danger-text": _vm.$t("shops.errors.name_is_required")
                  },
                  model: {
                    value: _vm.tax.name,
                    callback: function($$v) {
                      _vm.$set(_vm.tax, "name", $$v)
                    },
                    expression: "tax.name"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-32",
                  attrs: { name: "taxName" },
                  model: {
                    value: _vm.tax.translations[language].name,
                    callback: function($$v) {
                      _vm.$set(_vm.tax.translations[language], "name", $$v)
                    },
                    expression: "tax.translations[language].name"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-col w-1/3" },
      [
        _c("label", { staticClass: "vs-input--label w-1/2" }, [
          _vm._v(_vm._s(_vm.$t("shops.percent")))
        ]),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max_value:100",
              expression: "'required|max_value:100'"
            }
          ],
          staticClass: "w-32",
          attrs: {
            name: "percent",
            danger: _vm.errorPercent && _vm.errors.has("percent")
          },
          model: {
            value: _vm.tax.percent,
            callback: function($$v) {
              _vm.$set(_vm.tax, "percent", $$v)
            },
            expression: "tax.percent"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }